@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes fadeInBlockFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0.01;
	}

	100% {
		display: block;
		opacity: 1;
	}
}
