.loginSuccess {
	&__page-heading {
		margin: 0 0 -0.5em 0;
		font-size: 1.75em;
		font-weight: $global-font-weight-medium;
	}

	&__divider {
		@extend .footer__divider;
	}

	&__description {
		margin-block-start: 2em;
		margin-block-end: 3em;
	}
}