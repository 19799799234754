.login-form {
	&__convergence-banner {
		display: block;
		color: $color-grey-darkest;
		box-sizing: border-box;
		padding: 0;
		max-width: $max-page-width;
		min-width: $min-page-width;
		margin: 1rem auto 0;
	}

	&__convergence-banner-bottom {
		display: block;
		color: $color-grey-darkest;
		box-sizing: border-box;
		padding: 0;
		max-width: $max-page-width;
		min-width: $min-page-width;
		margin: 0 auto 2rem;
	}

	&__convergence-banner-logo-container {
		display: flex;
		justify-content: center;
		margin-bottom: 0.5rem;
	}

	&__convergence-banner-description-container {
		padding: 0.25rem 3rem;
		display: flex;
		justify-content: left;
	}

	&__divider {
		width: 100%;
		border: none;
	}

	&__convergence-banner-svg {
		@include sprite-width(2.75rem);

		display: inline-block;
		height: 2.1rem;
		width: 5.7rem;
		margin-right: 1rem;
	}

	&__last-svg {
		margin-right: 0;
	}

	&__last-two-svg-group {
		display: inline-block;
	}
}
