.create-account-form {
	&__email &__email-description {
		display: none;
	}

	&__email.input-group--dirty &__email-description {
		display: block;
	}

	&__new-password {
		.input-group--dirty .password-strength {
			display: block;
		}
	}

	&__sign-in-cta {
		margin: 1rem auto;
		max-width: 12rem;
	}

	&__name {
		display: flex;
	}

	&__firstName.input-group {
		margin: 1.5rem 1.5rem 0 0;
	}

	&__lastName.input-group {
		margin-bottom: 0;
	}

	&__secondary-cta {
		display: block;
		text-align: center;
	}

	&__privacy-terms-description {
		font-weight: $global-font-weight-medium;
	}

	&__convergence-box {
		display: inline-block;
		box-sizing: border-box;
		padding: 0 1rem;
		max-width: $max-banner-width;
		min-width: $min-page-width;
		margin: 0 auto 0;
		vertical-align: top;
	}

	&__convergence-banner {
		display: inline-block;
		color: $color-grey-darkest;
		box-sizing: border-box;
		padding: 1rem 3.75rem;
		border: 1px solid $grey-lightest;
		width: 525px;
		max-width: $max-page-width;
		min-width: $min-page-width;
		margin: 1rem auto 0;
		vertical-align: top;
	}

	&__divider {
		width: 100%;
		border-top: 1px solid $grey-lightest;
	}

	&__convergence-banner-logo-container {
		display: flex;
		justify-content: center;
		margin-bottom: 0.5rem;
	}

	&__convergence-banner-description1-container {
		padding: 0.25rem 2.75rem;
		display: flex;
		justify-content: left;
	}

	&__convergence-banner-description-container {
		padding: 0.25rem 0;
		display: flex;
		justify-content: left;
		text-align: left;
	}

	&__convergence-banner-description-check {
		@include sprite-width(2.75rem);

		display: block;
		height: 1rem;
		width: 1rem;
		margin: 0;
		// flex-basis: 4rem;
	}

	&__convergence-banner-svg {
		@include sprite-width(2.75rem);

		display: inline-block;
		height: 2.1rem;
		width: 5.7rem;
		margin-right: 1rem;
	}

	&__last-svg {
		margin-right: 0;
	}

	@media screen and (max-width: $media-m1-max) {
		&__confirm-password #confirmPassword__label,
		&__confirm-password button {
			max-width: 50%;
		}

		&__confirm-password button {
			text-align: right;
		}
	}

	@media screen and (max-width: $media-m3-max) {
		&__convergence-box {
			display: block;
			padding: 0;
		}

		&__convergence-banner {
			max-width: 22.5rem;
			padding: 1rem;
			width: auto;
		}

		&__convergence-banner-description1-container {
			padding-left: 0.5em;
			padding-right: 0;
			justify-content: center;
		}
	}
}

.create-account-success {
	&__subheading {
		font-weight: 500;
		margin: 1.5rem 0;
	}

	&__email {
		display: flex;
		align-items: center;
		margin-top: 2rem;
		font-weight: 500;

		.sprite {
			@include sprite-width(1.375rem);

			fill: $color-green;
			height: 1.375rem;
			margin-right: 0.5rem;
		}
	}

	&__image {
		max-width: 100%;
		margin-top: 1.5rem;
	}

	&__next-step {
		margin-top: 1.75rem;
	}
}
