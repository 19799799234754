.oops-page {
	border-color: transparent;
	text-align: center;

	&__link {
		margin-top: 3rem;
	}

	&__relying-party-list {
		text-align: left;
	}

	& > .layout__heading {
		flex-direction: column;
	}

	& > .layout__message {
		text-align: center;
	}
}

@media screen and (max-width: $media-m1-max) {
	.oops-page__link {
		margin-top: 2rem;
	}
}
