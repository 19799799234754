/*
 * Contains default styles for the site
 */

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: $global-font-family;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	color: $base-text-color;
}
