.visually-hidden {
	visibility: hidden;

	&--focusable {
		visibility: hidden;
	}
}

@mixin sprite-width($width) {
	flex-basis: $width;
	width: $width;
	min-width: $width;
}
