.updateSecurity {
	&__icon {
		text-align: center;
		margin: 2rem 0;
	}

	&__description1 {
		font-weight: $global-font-weight-medium;
	}

	&__description2 {
		margin-bottom: 1.5rem;
	}

	&__askForResend {
		margin-top: 1.5rem;
	}

	&__resendButton {
		font-size: 0.875rem;
		text-decoration: underline;
		font-weight: $global-font-weight-regular;
	}
}