.mfa-challenge-page {
	&__step-indicator {
		padding-bottom: 2rem;
	}

	&__email-security-warning {
		display: flex;
		padding-bottom: 1rem;
	}

	&__notice-icon {
		width: 1.25rem;
		height: 1.25rem;
		flex-shrink: 0;
		margin-right: 0.5rem;
	}
}