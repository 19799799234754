$color-white: #fff;

// BLACK AND GREY
$color-grey: #888;
$color-grey-dark: #666;
$color-grey-darkest: #333;
$color-black: #191919;

// PC ID
$primary: $color-grey-darkest;
$secondary: #2160a9;
$color-red: #ee1e29;
$color-green: #00853d;

// NOTIFICATIONS
$error-red: #970012;
$error-red--faded: #ffebea;
$color-yellow: #ffaf4e;
$color-light-yellow: #fff0df;
$color-peach: #fff0e0;
$color-light-green: #e7f3ed;
$color-a11y-green: #00421f;
$color-background-yellow: #ffefc5;

// INPUT GROUP PLACEHOLDERS
$grey-light: #999;
$grey-lighter: #aaa;
$grey-lightest: #ccc;

$disabled: $color-grey;
$disabled-background: #f5f5f5;
$disabled-border-color: $grey-lightest;
