.continue-as-page {
	text-align: center;

	&__pcid-logo {
		max-width: 58px;
		max-height: 32px;
	}

	&__heading {
		font-weight: $global-font-weight-medium;
	}

	&__description {
		padding: 1rem 0 1.5rem 0;
		font-size: 1rem;
	}

	&__privacy-terms {
		margin-top: 3rem;
		font-weight: $global-font-weight-regular;
		font-size: 0.8rem;
	}

	&__submit-button {
		background: $color-grey-darkest;
		color: $color-white;
		font-weight: $global-font-weight-medium;
		font-size: 0.9rem;
		padding: 0.7rem 1rem;
		border-radius: 35px;
		width: 270px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&-email-icon {
			max-width: 20px;
			max-height: 20px;
			margin-right: 0.5rem;
			vertical-align: middle;
		}

		&:hover ~ .continue-as-page__hidden-email {
			visibility: visible;
		}
	}

	&__hidden-email {
		position: relative;
		display: flex;
		justify-content: center;
		transition: visibility 0.3s ease;
		visibility: hidden;

		&-container {
			position: absolute;
			min-width: 100%;
		}

		&-triangle {
			width: 0;
			border: 8px solid transparent;
			border-bottom-color: $color-grey-darkest;
			margin: 0 auto;
		}

		&-display {
			background: $color-grey-darkest;
			color: $color-white;
			font-size: 1rem;
			margin: 0;
			padding: 0.7rem 1rem;
		}

		&-text {
			margin: 0;
		}
	}

	&__divider {
		margin: 2rem 0;
	}

	&__secondary-cta {
		font-weight: $global-font-weight-medium;
	}
}