.enterNumber {
	&__page-heading {
		margin: 0 0 -0.5em 0;
		font-size: 1.75em;
		font-weight: $global-font-weight-medium;
	}

	&__divider {
		@extend .footer__divider;
	}

	&__step-indicator {
		padding-bottom: 2rem;
	}

	&__field-heading {
		font-size: 1.5em;
		font-weight: $global-font-weight-medium;

		&-mfa {
			text-align: center;
		}
	}

	&__no-top-margin {
		margin-block-start: 0;
	}

	&__image-container {
		width: 100%;
		text-align: center;
		margin-bottom: 1rem;
	}
}