.protectAccount {
	&__step-indicator {
		padding-bottom: 2rem;
	}

	&__field-heading {
		font-size: 1.5em;
		font-weight: $global-font-weight-medium;
		margin-block-end: 1em;

		&-mfa {
			text-align: center;
		}
	}

	&__no-top-margin {
		margin-block-start: 0;
	}

	&__image-container {
		width: 100%;
		text-align: center;
	}

	&__list {
		margin-block-start: 1rem;
		margin-block-end: 1.2rem;
		padding: 0;

		&-item {
			display: flex;
			align-items: center;
			list-style: none;
			margin-bottom: 1rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-icon {
			max-width: 0.6rem;
			max-height: 0.6rem;
			vertical-align: middle;
			margin-top: -0.3rem;
			padding-right: 1rem;
		}
	}

	&__mfa-why {
		color: #194b9f;
		text-align: center;
		text-decoration: underline;
		padding-bottom: 2.2rem;

		&-icon {
			max-width: 1.4rem;
			max-height: 1.4rem;
			vertical-align: middle;
			margin-right: 0.6rem;
		}
	}
}