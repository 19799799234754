// for screen-reader only content
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

// for screen-reader content that is focusable, like a "skip to X" link
.sr-only-focusable {
	&:active,
	&:focus {
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}

sup {
	font-size: 0.5rem;
}

a {
	color: $base-font-color;
}
