/* GLOBAL------------------------------------------------------------------------------------------ */
$font-stack-sans: 'Noir', sans-serif;
$font-pcid-body: 'Noir', sans-serif;
$font-pcid-bold: 'Noir', sans-serif;
$global-font-family: 'Noir', sans-serif;

$global-font-weight-light: 200;
$global-font-weight-book: 300;
$global-font-weight-regular: 400;
$global-font-weight-medium: 500;
$global-font-weight-demibold: 600;
$global-font-weight-bold: 700;
$global-font-weight-black: 800;

/* ------------------------------------------------------------------------------------------------- */

/* BASE--------------------------------------------------------------------------------------------- */
$base-font-size: 16px;
$base-font-weight: $global-font-weight-regular;
$base-font-color: $color-grey-darkest;
$base-background-color: $color-white;
$base-text-color: $color-grey-darkest;
$base-border-color: $color-grey;

/* ------------------------------------------------------------------------------------------------- */

/* Input Group ------------------------------------------------------------------------------------- */
$anchor-transition-speed: 0;
$input-horizontal-padding: 1rem;

/* ------------------------------------------------------------------------------------------------- */

$max-page-width: 525px;
$min-page-width: 290px;
$max-banner-width: 555px;

$form-element-vertical-margin: 1.5rem;
