.terms-page {
	border-color: transparent;

	ol {
		line-height: 1.2;
		margin-top: 2rem;
		padding-left: 0;
	}

	li {
		list-style-position: inside;
		font-weight: 500;

		> h2 {
			font-size: 0.875rem;
			display: inline;
			margin: 0;
			font-weight: 500;
		}

		> p {
			font-weight: 400;
		}
	}
}
